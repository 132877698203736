import React from "react";
import { Helmet } from 'react-helmet';

const Meet = () => {
  return <>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <iframe src="https://wisoftify.whereby.com/guilian-ganster" allow="camera; microphone; fullscreen; speaker; display-capture" style={{width: "100%", height: "100vh"}}></iframe>
  </>
}

export default Meet;